<template>
  <div v-loading="pageloading">
    <el-form :model="form" ref="ruform" label-width="120px" :rules="rules" style="margin-bottom:100px">
      <el-card>
        <template slot="header">推手招募</template>
        <el-form-item label="加入条件：" required >
          <el-radio-group v-model="form.IsHavethreshold">
            <el-radio :label="0">有门槛授权</el-radio>
            <el-radio :label="1">无门槛授权</el-radio>
          </el-radio-group>
          <div v-if="form.IsHavethreshold==0" style="margin-top:10px">
            <div class="secgray-box">
              <el-form-item label="" prop="num1" class="error-font-money" :rules="form.thresholdType==1?rules.ThresholdAmount:rules.nocheck">
                <div class="flex flex-align-center">
                  <el-radio v-model="form.thresholdType" :label="1" style="margin:0px">累计完成交易</el-radio>
                  <el-input style="width:100px;margin:0px 10px" v-model.trim="form.num1" onkeyup="value=value.replace(/[^0-9.]/g,'')"
                    oninput="value=value.replace(/[^0-9.]/g,'')" :disabled="form.thresholdType!=1"></el-input>
                  <span style="color:#606266FF">元</span>
                </div>
                <div class="grayfont-12" style="margin:5px 0px 0px 25px">客户确认收货成功即计入完成交易金额；订单确认收货前退款成功的金额不计入在内</div>
              </el-form-item>
              <div style="margin-top:20px">
                <el-radio v-model="form.thresholdType" :label="0" style="margin:0px">购买授权礼包商品</el-radio>
                <div class="flex flex-align-center">
                  <div class="grayfont-12" style="margin-left:25px">成功购买授权礼包商品后，自动授权成为推手。授权礼包商品请前往商品列表管理</div>
                  <el-button type="text" style="margin:0px 20px;padding:0px;font-size:12px" @click="toshoplist">前往商品列表</el-button>
                  <el-link href="https://jusnn6k8al.feishu.cn/docx/Ky7ydoZWzos9abxP45XcZKzfnWA?from=from_copylink" :underline="false"
                  type="primary" target="_blank" style="line-height:1;font-size:12px">查看操作说明</el-link>
                </div>
              </div>
              <el-form-item label="" prop="num3" class="error-font-money" style="margin-top:20px" :rules="form.thresholdType==3?rules.ThresholdAmount3:rules.nocheck">
                <div class="flex flex-align-center">
                  <el-radio v-model="form.thresholdType" :label="3" style="margin:0px">余额单笔充值</el-radio>
                  <el-input style="width:100px;margin:0px 10px" v-model.trim="form.num3" onkeyup="value=value.replace(/[^0-9.]/g,'')"
                    oninput="value=value.replace(/[^0-9.]/g,'')" :disabled="form.thresholdType!=3"></el-input>
                  <span style="color:#606266FF">元</span>
                </div>
                <div class="flex flex-align-center">
                  <div class="grayfont-12" style="margin:5px 0px 0px 25px">余额单笔充值达到门槛后，自动授权成为推手。</div>
                  <el-button type="text" style="margin:0px 20px;padding:0px;font-size:12px" @click="torules">前往配置余额储值规则</el-button>
                </div>
              </el-form-item>
            </div>
          </div>
          <div class="grayfont-12" v-else>
            客户进入推手招募页，绑定手机号即可授权成为推手
          </div>
        </el-form-item>
        <el-form-item label="推手招募页：" required style="margin-top:50px">
          <el-radio-group v-model="form.DriverRecruitmentPageTemplateType">
            <el-radio :label="0">使用系统模板</el-radio>
            <el-radio :label="1">自定义页面</el-radio>
          </el-radio-group>
        </el-form-item>
        <div v-show="form.DriverRecruitmentPageTemplateType==0" style="padding-left:120px">
          <pagemode :config="form.Config"></pagemode>
        </div>
        <div v-show="form.DriverRecruitmentPageTemplateType==1">
          <div class="flex flex-align-center" style="margin-bottom:20px">
            <div class="label-font">页面标题</div>
            <el-input v-model="form.Config.title" style="width:375px" maxlength="20" placeholder="若为空，默认为：推手招募"></el-input>
          </div>
          <div class="flex flex-align-center" style="margin-bottom:20px">
            <div class="label-font">邀请语</div>
            <el-input v-model="form.Config.welcome" style="width:375px" maxlength="20" :placeholder="'若为空，默认为：邀请你一起加入'+mallInfo.PcMallName"></el-input>
          </div>
          <div class="flex" style="margin-bottom:20px">
            <div class="label-font">邀请内容</div>
            <quill-editor ref="welcomecontent" v-model="form.Config.customContent" :options="quillOption"
              style="margin:0px 0px 100px 0px;width:375px;height: 1000px;">
            </quill-editor>
          </div>
        </div>
      </el-card>
    </el-form>
    <el-card class="bottom-banner">
      <div style="margin-left:8%"></div>
      <el-button type="primary" @click="save('ruform')">保存</el-button>
      <el-button type="primary" style="background:#fff;color:#409eff" plain @click="promoteShow=true">招募推广</el-button>
    </el-card>
    <!-- //推广 -->
    <promote :visible="promoteShow" :data="form" @close="closepromote"></promote>
  </div>
</template>

<script>
import {
  mapGetters
} from 'vuex'
import quillConfig from '@/components/quill-config-nolink.js'
import pagemode from '@/views/distribution/driverrecruiting/components/pagemode.vue'
import promote from '@/views/distribution/driverrecruiting/components/promote.vue'
import {
  malldriverRecruitmentConfig,
  malldriverRecruitmentConfigSave
} from '@/api/sv3.0.0'
export default {
  components: {
    pagemode,
    promote
  },
  computed: {
    ...mapGetters([
      'mallInfo'
    ]),
  },
  data () {
    var checkThresholdAmount = (rule, value, callback) => {
      if(this.form.IsHavethreshold==0&&this.form.thresholdType!=0){
        let two = /^\d+(\.\d{1,2})?$/
        console.log(rule)
        if(value < 0.01 || value > 99999 || !two.test(value)){
          callback(new Error('金额请输入0.01~99999元之间，最多2位小数'))
        }else{
          callback()
        }
      }else{
        callback()
      }
    };
    var checkThresholdAmount3 = (rule, value, callback) => {
      if(this.form.IsHavethreshold==0&&this.form.thresholdType!=0){
        let two = /^\d+(\.\d{1,2})?$/
        console.log(rule)
        if(value < 0.01 || value > 20000 || !two.test(value)){
          callback(new Error('金额请输入0.01~20000元之间，最多2位小数'))
        }else{
          callback()
        }
      }else{
        callback()
      }
    };
    var nocheck = (rule, value, callback) => {
      callback()
    }
    return {
      pageloading:false,
      form:{
        IsHavethreshold:0,//有无门槛
        thresholdType:0,//有门槛的类型
        ThresholdAmount:'',
        DriverRecruitmentPageTemplateType:0,
        Config:{},
        DriverRecruitmentPageShareCardImgUrl:'',
        DriverRecruitmentPageShareDescription:'',
        num1:'',
        num3:'',
      },
      rules:{
        ThresholdAmount:[
          { validator: checkThresholdAmount, trigger: 'blur' }
        ],
        ThresholdAmount3:[
          { validator: checkThresholdAmount3, trigger: 'blur' }
        ],
        nocheck:[
          {require:false,validator: nocheck,}
        ]
      },
			quillOption: quillConfig,
      promoteShow:false,
    }
  },
  mounted () {
    this.getinfo()
  },
  methods:{
    closepromote(val,refresh){
      this.promoteShow = val
      if(refresh){
        this.getinfo()
      }
    },
    async getinfo(){
      try{
        this.pageloading = true
        let res = await malldriverRecruitmentConfig()
        if(res.IsSuccess){
          let form = res.Result
          //DriverAuthThresholdType 购买授权礼包授权 = 0,累计完成交易 = 1,无门槛授权 = 2
          if(form.DriverAuthThresholdType==0){
            form.IsHavethreshold = 0
            form.thresholdType = 0
            form.ThresholdAmount = ''
            form.num1 = ''
            form.num3 = ''
          }else if(form.DriverAuthThresholdType==1){
            form.IsHavethreshold = 0
            form.thresholdType = 1
            form.num1 = form.ThresholdAmount
            form.num3 = ''
          }else if(form.DriverAuthThresholdType==3){
            form.IsHavethreshold = 0
            form.thresholdType = 3
            form.num3 = form.ThresholdAmount
            form.num1 = ''
          }else{
            form.IsHavethreshold = 1
            form.thresholdType = 0
            form.ThresholdAmount = ''
            form.num1 = ''
            form.num3 = ''
          }
          // this.form.DriverRecruitmentPageTemplateType = form.DriverRecruitmentPageTemplateType
          let config = {
            title:'推手招募',//页面标题
            welcome:'邀请你一起加入'+ this.mallInfo.PcMallName,//邀请语
            bgImage:'/image/pagemode-bg.png',//背景图
            mainTitle:'推手招募令',//主标题
            subtitle:'欢迎加入我们，自购省钱，分享赚钱',//副标题
            lightColor:'#C0391F',//浅色
            darkColor:'#8C1E09',//深色
            list:[
              {
                title:'如何成为推手？',
                content:'达到授权门槛后，点击下方【立即成为推手】按钮，注册成为推手'
              },
              {
                title:'推手如何获得收益？',
                content:'1.成为推手后，分享商品或商城其他页面给微信好友，或者点击页面内的【赚】字，生成分享海报至微信朋友圈\n2.好友通过你分享的链接进入商城成交，你可获得收益\n3.好友确认收货后，系统自动结算收益，你可提现已结算的收益'
              },
              {
                title:'推手收益说明',
                content:'1.好友通过你分享的链接进入商城成交，你可获得推手收益\n2.订单确认收货前，退款成功的部分，相应的收益将会扣除\n3.订单确认收货后，可提现收益'
              },
            ],
            customContent:''//自定义内容
          }
          form.Config = form.DriverRecruitmentPageConfig?JSON.parse(form.DriverRecruitmentPageConfig):config
          this.form = form
          // console.log(this.form)
        }
      }finally{
        this.pageloading = false
      }
    },
    async submit(){
      let form = JSON.parse(JSON.stringify(this.form))
      if(form.IsHavethreshold==0){
        if(form.thresholdType==0){
          form.DriverAuthThresholdType = 0
          form.ThresholdAmount = ''
        }else if(form.thresholdType==1){
          form.DriverAuthThresholdType = 1
          form.ThresholdAmount = form.num1
        }else if(form.thresholdType==3){
          form.DriverAuthThresholdType = 3
          form.ThresholdAmount = form.num3
        }else{
          form.DriverAuthThresholdType = 1
        }
      }else{
        form.DriverAuthThresholdType = 2
        form.ThresholdAmount = ''
      }
      let list = [
        {
          title:'如何成为推手？',
          content:'达到授权门槛后，点击下方【立即成为推手】按钮，注册成为推手'
        },
        {
          title:'推手如何获得收益？',
          content:'1.成为推手后，分享商品或商城其他页面给微信好友，或者点击页面内的【赚】字，生成分享海报至微信朋友圈\n2.好友通过你分享的链接进入商城成交，你可获得收益\n3.好友确认收货后，系统自动结算收益，你可提现已结算的收益'
        },
        {
          title:'推手收益说明',
          content:'1.好友通过你分享的链接进入商城成交，你可获得推手收益\n2.订单确认收货前，退款成功的部分，相应的收益将会扣除\n3.订单确认收货后，可提现收益'
        },
      ]
      let config = {
        title:form.Config.title||'推手招募',
        welcome:form.Config.welcome||('邀请你一起加入'+ this.mallInfo.PcMallName),
        bgImage:form.Config.bgImage||'/image/pagemode-bg.png',
        mainTitle:form.Config.mainTitle,
        subtitle:form.Config.subtitle,
        lightColor:form.Config.lightColor||'#C0391F',
        darkColor:form.Config.darkColor||'#8C1E09',
        list:form.Config.list.map((v,i)=>{
              // v.title = v.title||list[i].title
              // v.content = v.content||list[i].content
              return v
            }),
        customContent:form.Config.customContent||'',
      }
      form.DriverRecruitmentPageConfig = JSON.stringify(config)
      let res = await malldriverRecruitmentConfigSave(form)
      if(res.IsSuccess){
        this.$message.success('保存成功')
        this.getinfo()
      }
    },
    save(formName){
      console.log(this.form)
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submit()
        } else {
          this.$message.error('请完善推手招募配置')
          this.$nextTick(()=>{
            const element = document.querySelectorAll('.el-form-item__error')[0] 
            // 滚动到错误元素对应位置 
            element.scrollIntoView({ 
              behavior: 'smooth', 
              block: 'center' 
            }) 
          })
          return false;
        }
      });
    },
    toshoplist(){
      this.$router.push({
        path:'/goods/goodsList'
      })
    },
    torules(){
      this.$router.push({
        path:'/Customer/storedrule'
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .flex{
    display: flex;
  }
  .flex-align-center{
    align-items: center;
  }
  .flex-column{
    flex-direction: column;
  }
  .grayfont-12{
    color: #999999;
    line-height: 1.5;
    font-size: 12px;
  }
  .secgray-box{
    padding:16px;
    background: rgb(251,251,251);
    display: inline-block;
  }
  .label-font{
    color: #606266;
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;
    flex-shrink: 0;
    width: 100px;
    text-align: right;
    margin-right: 10px;
  }
  .bottom-banner{
    position: fixed;
    bottom: 0;
    left: 230px;
    right: 0;
    height: 76px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000;
    background: white;
  }

	@media screen and (max-width:1280px) {
		.bottom-banner {
			left: 150px
		}
	}
  
  .error-font-money{
    ::v-deep .el-form-item__error{
      left: 25px;
    }
  }
</style>

<style lang="less">

</style>